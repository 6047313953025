<template>
  <div class="page-main">
    <b-container class="mb-5 static-page">
      <div v-html="html"></div>
    </b-container>
  </div>
</template>
<script>
export default {
  props: ["page"],
  computed: {
    html() {
      return this.page?.html[this.locale];
    },
    locale() {
      return this.$root.$i18n.locale;
    },
  },
};
</script>
<style lang="scss">
.center {
  text-align: center;
}
.page-main {
  flex-grow: 1;
  // background-color: white;
}
.static-page {
  padding: 25px;
  // background-color: rgb(0 0 0 / 40%);
  background-color: white;
}
.ql-align-center {
  text-align: center;
}
</style>
